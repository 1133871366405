import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { InputTextarea } from 'primereact/inputtextarea';
import { CredentialIssuerDefinitionForm } from '../../state/slices/credentialissuerdefinitionform';
import {
    CredentialIssuerCredentialDefinition,
    CredentialIssuerDefinition,
    credentialIssuerDefinitionSelector,
    issueCredentialDefinition,
    useAppDispatch
} from '../../state';
import { Did, didSelector, getDids } from '../../state/slices/did';
import { DidSelectList, OCard, OFabContainer, TextInputWithLabel } from '../molecules';
import { LanguageInput } from '../molecules/LanguageInput';
import { CredentialStatusList, credentialStatusSelector, getCredentialStatusConfigurations } from '../../state/slices/credentialstatus';
import { CredentialStatusListSelectList } from '../molecules/CredentialStatusListSelectList';
import QRCode from 'react-qr-code';

export interface IssuerCredentialAttributesFormProps {
    credentialIssuerCredential?: CredentialIssuerCredentialDefinition;
}

export const IssuerCredentialAttributesForm: FC<IssuerCredentialAttributesFormProps> = (props) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak, initialized} = useKeycloak();
    let navigation = useNavigate();
    const [selectedStatusList, setSelectedStatusList ] = useState<CredentialStatusList>();
    const [credentialForm, setCredentialForm ] = useState<any>({});
    const [oidcIssueUrl, setOidcIssueUrl] = useState<string>();

    function handleFormUpdate(attributeName: string, value: string | undefined) {
        setCredentialForm(Object.assign({}, credentialForm, {[attributeName]: (typeof value == 'string') ? value.trimStart() : value}));
    }

    return (
        <>
                <OCard className="mt-4" title='Credential attributes'>
                    {(props.credentialIssuerCredential && props.credentialIssuerCredential.attributes && props.credentialIssuerCredential.attributes.length > 0) && (
                            <div>
                                {props.credentialIssuerCredential.attributes.map((attribute, index) => (
                                    <TextInputWithLabel className="mb-3"
                                                        label='Attribute name'
                                                        placeHolder={t('screens.credentialIssuerDefinitionForm.issue.placeHolder')}
                                                        value={attribute.attributeName!}
                                                        onChangeValue={(value) => handleFormUpdate(attribute.attributeName!, value)}/>
                                ))
                                }
                            </div>
                    )}
                    <Button className="p-2 m-2" >Add attribute</Button>
                </OCard>
        </>
)
;
}
