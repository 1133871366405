import React, { FC, PropsWithChildren } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { BoldText } from "../atoms/texts/BoldText";
import keycloak from '../../keycloak';


interface AuthenticationProviderProps {
}

export const AuthenticationProvider: FC<AuthenticationProviderProps & PropsWithChildren> = (props) => {
    // "enable-cors": true
    const initOptions = {pkceMethod: 'S256'}


    const loadingComponent = (
        <BoldText></BoldText>
    )

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={initOptions}
            LoadingComponent={loadingComponent}>
            {props.children}
        </ReactKeycloakProvider>)
};
